import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { appActions } from 'src/store/app'
function RequireAuth({ children }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const tokenLocal = localStorage.getItem('token')
  useEffect(() => {
    dispatch(appActions.digestValueHandler(location?.pathname?.split('/')[2]))
  }, [])

  if (tokenLocal) {
    return children
  }

  if (!tokenLocal && location?.pathname?.split('/')[1] === 'reset-password') {
    return <Navigate to="/reset-password" />
  }

  if (!tokenLocal) {
    return <Navigate to="/login" replace />
  }
}

RequireAuth.propTypes = {
  children: PropTypes.any,
}

export default RequireAuth
