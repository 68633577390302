import { createSlice } from '@reduxjs/toolkit'

const initialReportState = {
  scheduledReportsData: {},
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialReportState,
  reducers: {
    scheduledReportsDataHandler(state, action) {
      return {
        ...state,
        scheduledReportsData: action.payload,
      }
    },
  },
})

export const reportsActions = reportsSlice.actions
export default reportsSlice.reducer
