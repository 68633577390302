import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import RequireAuth from './utils/RequireAuth'
import { useDispatch } from 'react-redux'
import { appActions } from './store/app'
import { fetchAppSettings } from './rest_api/restApiCollection'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))

const App = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    settingsApplication()
  },[])

  const settingsApplication=()=>{
    fetchAppSettings().then((res)=>{
      if(res?.status === 200){
        dispatch(appActions.appSettingsHandler(res?.data?.data))
      }
    })
  }
  
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/reset-password"
            name="Reset Password Page"
            element={<ResetPassword />}
          />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password Page"
            element={<ForgotPassword />}
          />

          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={
              <RequireAuth>
                <DefaultLayout />
              </RequireAuth>
            }
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
