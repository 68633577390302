import { createSlice } from '@reduxjs/toolkit'

const initialDeviceLogsState = {
  siteFilterName: '',
  laneFilterName: '',
  deviceFilterName: '',
  deviceIdentifierFilterName: '',
  locationFilterName: '',
  selectedSiteDataRedux: [],
  startDateRedux: '',
  endDateRedux: '',
}

const deviceLogsSlice = createSlice({
  name: 'deviceLogs',
  initialState: initialDeviceLogsState,
  reducers: {
    siteFilterNameHandler(state, action) {
      return {
        ...state,
        siteFilterName: action.payload,
      }
    },
    laneFilterNameHandler(state, action) {
      return {
        ...state,
        laneFilterName: action.payload,
      }
    },

    deviceFilterNameHandler(state, action) {
      return {
        ...state,
        deviceFilterName: action.payload,
      }
    },

    deviceIdentifierFilterNameHandler(state, action) {
      return {
        ...state,
        deviceIdentifierFilterName: action.payload,
      }
    },

    locationFilterNameHandler(state, action) {
      return {
        ...state,
        locationFilterName: action.payload,
      }
    },

    selectedSiteDataHandler(state, action) {
      return {
        ...state,
        selectedSiteDataRedux: action.payload,
      }
    },

    startDateHandler(state, action) {
      return {
        ...state,
        startDateRedux: action.payload,
      }
    },

    endDateHandler(state, action) {
      return {
        ...state,
        endDateRedux: action.payload,
      }
    },
    clearFiltersHandler(state) {
      return {
        ...state,
        siteFilterName: '',
        laneFilterName: '',
        deviceFilterName: '',
        deviceIdentifierFilterName: '',
        locationFilterName: '',
        selectedSiteDataRedux: [],
        startDateRedux: '',
        endDateRedux: '',
      }
    },
  },
})

export const deviceLogsActions = deviceLogsSlice.actions
export default deviceLogsSlice.reducer
