import { createSlice } from '@reduxjs/toolkit'

const initialAppState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  loginFlag: false,
  userNameRedux: localStorage.getItem('userName'),
  digestValueRedux: '',
  timeZoneValue: localStorage.getItem('timeZone'),
}

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    appSideBarHandler(state, action) {
      return {
        ...state,
        sidebarShow: action.payload,
      }
    },
    appSideBarUnfoldableHandler(state, action) {
      return {
        ...state,
        sidebarUnfoldable: action.payload,
      }
    },
    loginFlaghandler(state, action) {
      return {
        ...state,
        loginFlag: action.payload,
      }
    },
    userToken(state, action) {
      return {
        ...state,
        userToken: action.payload,
      }
    },
    userNameHandler(state, action) {
      return {
        ...state,
        userNameRedux: action.payload,
      }
    },
    digestValueHandler(state, action) {
      return {
        ...state,
        digestValueRedux: action.payload,
      }
    },
    timeZoneHandler(state, action) {
      return {
        ...state,
        timeZoneValue: action.payload,
      }
    },
    appSettingsHandler(state,action){
      return {
        ...state,
        isAzureAdLoginEnabled: action?.payload?.isAzureAdLoginEnabled,
        isFirmwareUpdateFileUploadEnabled: action?.payload?.isFirmwareUpdateFileUploadEnabled
      }
    }
  },
})

export const appActions = appSlice.actions
export default appSlice.reducer
