import { createSlice } from '@reduxjs/toolkit'

const initialAccountState = {
  userIdListArray: [],
  accountNameValue: '',
  accountStatusValue: 0,
  accountPhoneValue: '',
  userPhoneNumber: '',
  userLoginValue: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userGroupId: 0,
  userMFAValue: '',
  userIdValue: 0,
  siteDescriptionValue: '',
  siteNameString: '',
  accountModules: [],
}

const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {
    userIdListHandler(state, action) {
      return {
        ...state,
        userIdListArray: action.payload,
      }
    },
    accountNameHandler(state, action) {
      return {
        ...state,
        accountNameValue: action.payload,
      }
    },
    accountStatusHandler(state, action) {
      return {
        ...state,
        accountStatusValue: action.payload,
      }
    },
    accountPhoneNumberHandler(state, action) {
      return {
        ...state,
        accountPhoneValue: action.payload,
      }
    },
    userPhoneNumberHandler(state, action) {
      return {
        ...state,
        userPhoneNumber: action.payload,
      }
    },
    userLoginValueHandler(state, action) {
      return {
        ...state,
        userLoginValue: action.payload,
      }
    },
    userGroupIdHandler(state, action) {
      return {
        ...state,
        userGroupId: action.payload,
      }
    },
    userFirstNameHandler(state, action) {
      return {
        ...state,
        userFirstName: action.payload,
      }
    },
    userLastNameHandler(state, action) {
      return {
        ...state,
        userLastName: action.payload,
      }
    },

    userEmailHandler(state, action) {
      return {
        ...state,
        userEmail: action.payload,
      }
    },
    userMFAValueHandler(state, action) {
      return {
        ...state,
        userMFAValue: action.payload,
      }
    },
    userIdValueHandler(state, action) {
      return {
        ...state,
        userIdValue: action.payload,
      }
    },
    accountModuleHandler(state, action) {
      return {
        ...state,
        accountModules: action.payload,
      }
    },
    setActiveModule(state, action) {
      return {
        ...state,
        activeModuleId: action.payload?.id,
        activeModuleName: action.payload?.name,
      }
    },
    auditlogEntityHandler(state, action) {
      return {
        ...state,
        entityName: action.payload,
      }
    },
    auditlogActionHandler(state, action) {
      return {
        ...state,
        actionName: action.payload,
      }
    },
    auditlogUsersHandler(state, action) {
      return {
        ...state,
        usersName: action.payload,
      }
    },
    startDateHandler(state, action) {
      return {
        ...state,
        startDt: action.payload,
      }
    },
    endDateHandler(state, action) {
      return {
        ...state,
        endDt: action.payload,
      }
    },
  },
})

export const accountActions = accountSlice.actions
export default accountSlice.reducer
