import { createSlice } from '@reduxjs/toolkit'

const initialSiteLaneState = {
  siteIdArray: [],
  siteListArray: [],
  laneIdListArray: [],
  laneActiveListArray: [],
  siteNameValue: '',
  siteDescriptionValue: '',
  siteNameString: '',
  siteIdValue: 0,
  laneNameValue: 0,
  laneSiteValue: 0,
  laneIpValue: '',
  lanePortValue: '',
  siteDevicePage: 1,
  laneDevicePage: 1,
  laneIdValue: 0,
  emailListValue: [],
  sitePermissionValue: false,
  userAcessList: [],
}

const siteLaneSlice = createSlice({
  name: 'siteLane',
  initialState: initialSiteLaneState,
  reducers: {
    siteIdArrayHandler(state, action) {
      return {
        ...state,
        siteIdArray: action.payload,
      }
    },
    siteListHandler(state, action) {
      return {
        ...state,
        siteListArray: action.payload,
      }
    },
    laneIdListHandler(state, action) {
      return {
        ...state,
        laneIdListArray: action.payload,
      }
    },
    laneActiveListHandler(state, action) {
      return {
        ...state,
        laneActiveListArray: action.payload,
      }
    },
    siteNameValueHandler(state, action) {
      return {
        ...state,
        siteNameValue: action.payload,
      }
    },
    siteDescriptionValueHandler(state, action) {
      return {
        ...state,
        siteDescriptionValue: action.payload,
      }
    },
    siteNameStringHandler(state, action) {
      return {
        ...state,
        siteNameString: action.payload,
      }
    },
    siteIdValueHandler(state, action) {
      return {
        ...state,
        siteIdValue: action.payload,
      }
    },
    laneIdValueHandler(state, action) {
      return {
        ...state,
        laneIdValue: action.payload,
      }
    },
    laneNameValueHandler(state, action) {
      return {
        ...state,
        laneNameValue: action.payload,
      }
    },
    laneSiteValueHandler(state, action) {
      return {
        ...state,
        laneSiteValue: action.payload,
      }
    },
    laneIpValueHandler(state, action) {
      return {
        ...state,
        laneIpValue: action.payload,
      }
    },
    lanePortValueHandler(state, action) {
      return {
        ...state,
        lanePortValue: action.payload,
      }
    },

    sitePageHandler(state, action) {
      return {
        ...state,
        siteDevicePage: action.payload,
      }
    },

    lanePageHandler(state, action) {
      return {
        ...state,
        laneDevicePage: action.payload,
      }
    },

    siteEmailListValueHandler(state, action) {
      return {
        ...state,
        emailListValue: action.payload,
      }
    },

    sitePermissionValueHandler(state, action) {
      return {
        ...state,
        sitePermissionValue: action.payload,
      }
    },

    siteUserAccessListHandler(state, action) {
      return {
        ...state,
        userAcessList: action.payload,
      }
    },
  },
})

export const siteLaneActions = siteLaneSlice.actions
export default siteLaneSlice.reducer
