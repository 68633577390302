import axios from 'axios'
import { config } from '../constants/config'

export const axiosInstance = axios.create({
  baseURL: config.restURL,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
      // Check if config.data is an instance of FormData and set the appropriate Content-Type
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
