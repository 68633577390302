import { createSlice } from '@reduxjs/toolkit'

const initialDashboardState = {
  dashboardList: [],
  dashboardId: null,
  tenMinTitleList: [],
  graphDataList: [],
  graphLabelListHandler: [],
  deviceListData: [],
  widgetOneGraphData: [],
  widgetId: null,
  widgetTypeId: null,
  widgetData: [],
  widgetXValue: null,
  widgetYValue: null,
  widgetWidthValue: null,
  widgetHeightValue: null,
  widgetSettingsData: {},
  saveDashboardFlag: false,
  widgetPositionSizeData: [],
  modalTypeRedux: '',
  hazmatDevice: '',
  hazmatLocation: '',
  hazmatLocationOption: [],
  deviceIdentifierData: [],
  deviceIdentifierValue: '',
  durationValue: null,
}

const dashboardSlice = createSlice({
  name: 'device',
  initialState: initialDashboardState,
  reducers: {
    dashboardListHandler(state, action) {
      return {
        ...state,
        dashboardList: action.payload,
      }
    },
    dashboardIdHandler(state, action) {
      return {
        ...state,
        dashboardId: action.payload,
      }
    },
    tenMinTitleListHandler(state, action) {
      return {
        ...state,
        tenMinTitleList: action.payload,
      }
    },
    graphDataListHandler(state, action) {
      return {
        ...state,
        graphDataList: action.payload,
      }
    },
    graphLabelListHandler(state, action) {
      return {
        ...state,
        graphLabelListHandler: action.payload,
      }
    },
    deviceListDataHandler(state, action) {
      return {
        ...state,
        deviceListData: action.payload,
      }
    },
    widgetOneGraphDataHandler(state, action) {
      return {
        ...state,
        widgetOneGraphData: action.payload,
      }
    },
    widgetIdHandler(state, action) {
      return {
        ...state,
        widgetId: action.payload,
      }
    },
    widgetDataHandler(state, action) {
      return {
        ...state,
        widgetData: action.payload,
      }
    },
    widgetXValueHandler(state, action) {
      return {
        ...state,
        widgetXValue: action.payload,
      }
    },
    widgetYValueHandler(state, action) {
      return {
        ...state,
        widgetYValue: action.payload,
      }
    },
    widgetWidthValueHandler(state, action) {
      return {
        ...state,
        widgetWidthValue: action.payload,
      }
    },
    widgetHeightValueHandler(state, action) {
      return {
        ...state,
        widgetHeightValue: action.payload,
      }
    },
    widgetTypeIdHandler(state, action) {
      return {
        ...state,
        widgetTypeId: action.payload,
      }
    },
    widgetSettingsDataHandler(state, action) {
      return {
        ...state,
        widgetSettingsData: action.payload,
      }
    },
    saveDashboardHandler(state, action) {
      return {
        ...state,
        saveDashboardFlag: action.payload,
      }
    },
    widgetPositionSizeDataHandler(state, action) {
      return {
        ...state,
        widgetPositionSizeData: action.payload,
      }
    },
    modalTypeHandler(state, action) {
      return {
        ...state,
        modalTypeRedux: action.payload,
      }
    },
    hazmatDeviceHandler(state, action) {
      return {
        ...state,
        hazmatDevice: action.payload,
      }
    },
    hazmatLocationHandler(state, action) {
      return {
        ...state,
        hazmatLocation: action.payload,
      }
    },
    hazmatLocationOptionHandler(state, action) {
      return {
        ...state,
        hazmatLocationOption: action.payload,
      }
    },

    deviceIdentifierDataHandler(state, action) {
      return {
        ...state,
        deviceIdentifierData: action.payload,
      }
    },
    deviceIdentifierValueHandler(state, action) {
      return {
        ...state,
        deviceIdentifierValue: action.payload,
      }
    },
    durationValueHandler(state, action) {
      return {
        ...state,
        durationValue: action.payload,
      }
    },
  },
})

export const dashboardActions = dashboardSlice.actions
export default dashboardSlice.reducer
