import { createSlice } from '@reduxjs/toolkit'

const initialDeviceState = {
  devicePage: 1,
  sitePage: 1,
  deviceLaneIdRedux: 0,
  deviceTypeIdRedux: 0,
  deviceCommentRedux: '',
  devicePortRedux: '',
  deviceEnableValue: '',
  deviceIdRedux: 0,
  deviceLocationData: {},
  deviceIdentifierRedux: '',
}

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialDeviceState,
  reducers: {
    devicePageHandler(state, action) {
      return {
        ...state,
        devicePage: action.payload,
      }
    },
    sitePageHandler(state, action) {
      return {
        ...state,
        sitePage: action.payload,
      }
    },

    deviceIdHandler(state, action) {
      return {
        ...state,
        deviceIdRedux: action.payload,
      }
    },
    deviceLaneIdHandler(state, action) {
      return {
        ...state,
        deviceLaneIdRedux: action.payload,
      }
    },
    deviceTypeIdHandler(state, action) {
      return {
        ...state,
        deviceTypeIdRedux: action.payload,
      }
    },
    deviceCommentHandler(state, action) {
      return {
        ...state,
        deviceCommentRedux: action.payload,
      }
    },
    devicePortHandler(state, action) {
      return {
        ...state,
        devicePortRedux: action.payload,
      }
    },
    deviceEnableHandler(state, action) {
      return {
        ...state,
        deviceEnableValue: action.payload,
      }
    },
    deviceLocationDataHandler(state, action) {
      return {
        ...state,
        deviceLocationData: action.payload,
      }
    },
    deviceIdentifierHandler(state, action) {
      return {
        ...state,
        deviceIdentifierRedux: action.payload,
      }
    },
    deviceFileHandler(state, action) {
      return {
        ...state,
        deviceFileRedux: action.payload,
      }
    },
    deviceFilesListHandler(state, action) {
      return {
        ...state,
        deviceFileListRedux: action.payload,
      }
    },
  },
})

export const deviceActions = deviceSlice.actions
export default deviceSlice.reducer
