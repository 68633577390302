import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import appReducer from './app'
import siteLaneReducer from './siteLane'
import deviceReducer from './device'
import deviceLogsReducer from './deviceLogs'
import dashboardReducer from './dashboard'
import accountReducer from './account'
import reportsReducer from './reports'
const reducer = combineReducers({
  app: appReducer,
  siteLane: siteLaneReducer,
  device: deviceReducer,
  deviceLogs: deviceLogsReducer,
  dashboard: dashboardReducer,
  account: accountReducer,
  reports: reportsReducer,
})
const store = configureStore({
  reducer,
})

export default store
