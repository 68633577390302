import { axiosInstance } from './axiosInstance'

export const userLogin = async (user, password) => {
  return axiosInstance.post('/auth/login', {
    user: user,
    password: password,
  })
}

export const fetchToken = async (req) => {
  return axiosInstance.post(`/auth/session-token`, req)
}

export const forgotPassword = async (emailId) => {
  return axiosInstance.post('/auth/forgot-password', {
    email: emailId,
  })
}

export const resetPassword = async (passwordValue, digestValue) => {
  return axiosInstance.post(`/auth/verify-user/${digestValue}`, {
    password: passwordValue,
  })
}

export const refreshToken = async (token) => {
  return axiosInstance.post('/auth/renewtoken')
}

export const getAccountList = async () => {
  return axiosInstance.get(`/accounts`)
}

export const getAccountModule = async () => {
  return axiosInstance.get(`/accounts/modules`)
}

export const getAccountDashboard = async () => {
  return axiosInstance.get(`/accounts/dashboard`)
}

export const getWidgets = async (dashboardId) => {
  return axiosInstance.get(`/widgets/${dashboardId}`)
}

export const getUsersList = async (devicePage, pageLimit, securityGroupId) => {
  return axiosInstance.get(`/users`, {
    params: {
      ...(devicePage !== '' ? { page: devicePage } : {}),
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(securityGroupId !== '' ? { securityGroup: securityGroupId } : {}),
    },
  })
}

export const getDevicesList = async (
  devicePage,
  siteOptionValue,
  laneOptionValue,
  deviceOptionValue,
  statusOptionValue,
  paginateFlag,
  timeZone,
  pageLimit,
) => {
  return axiosInstance.get(`/devices`, {
    params: {
      ...(devicePage !== '' ? { page: devicePage } : {}),
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteOptionValue !== '' ? { site: siteOptionValue } : {}),
      ...(laneOptionValue !== '' ? { lane: laneOptionValue } : {}),
      ...(deviceOptionValue !== '' ? { device_type: deviceOptionValue } : {}),
      ...(statusOptionValue !== '' ? { status: statusOptionValue } : {}),
      ...(paginateFlag !== '' ? { paginate: paginateFlag } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
    },
  })
}

export const getSites = async (devicePage, paginateFlag, siteLimit) => {
  return axiosInstance.get(`/sites`, {
    params: {
      ...(devicePage !== '' ? { page: devicePage } : {}),
      ...(siteLimit !== '' ? { limit: siteLimit } : {}),
      ...(paginateFlag !== '' ? { paginate: paginateFlag } : {}),
    },
  })
}

export const getLanes = async (siteName, devicePage, paginateFlag, laneLimit) => {
  return axiosInstance.get(`/lanes`, {
    params: {
      ...(devicePage ? { page: devicePage } : {}),
      ...(laneLimit ? { limit: laneLimit } : {}),
      ...(siteName ? { site: siteName } : {}),
      ...(paginateFlag !== '' ? { paginate: paginateFlag } : {}),
    },
  })
}

export const getEventStats = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/eventstats`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getEventSummary = async (
  startDateRedux,
  endDateRedux,
  midIdListValue,
  timeZone,
  noOfIntervalsValue,
  deviceCountType,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
) => {
  return axiosInstance.get(`/devicedata/eventstats-summary`, {
    params: {
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(midIdListValue !== '' ? { devices: midIdListValue } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(noOfIntervalsValue !== '' ? { noOfIntervals: noOfIntervalsValue } : {}),
      ...(deviceCountType !== '' ? { propertyName: deviceCountType } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { device: deviceFilterName } : {}),
    },
  })
}

export const getHazmatSummary = async (req) => {
  return axiosInstance.get(`/hazmat/readings-summary`, {
    params: req,
  })
}

export const getReadStats = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/readstats`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getAuditlog = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/auditlog`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getConfig = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/config`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getLastReboot = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/lastreboot`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getOsData = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/os`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getStatusData = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/status`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getStatusIdData = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/statusid`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getFirmware = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/firmware`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getheartBeat = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/heartbeat`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getInfoData = async (
  devicePage,
  siteFilterName,
  laneFilterName,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/devicedata/info`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(siteFilterName !== '' ? { site: siteFilterName } : {}),
      ...(laneFilterName !== '' ? { lane: laneFilterName } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getAlerts = async (
  devicePage,
  deviceFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/alerts`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(startDateRedux !== '' ? { range_start: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { range_end: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

export const getAlertsSummary = async (
  startDateRedux,
  endDateRedux,
  timeZone,
  noOfIntervalsValue,
  deviceType,
  dashboardDevices,
) => {
  return axiosInstance.get(`/alerts/summary`, {
    params: {
      ...(startDateRedux !== '' ? { range_start: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { range_end: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(noOfIntervalsValue !== '' ? { noOfIntervals: noOfIntervalsValue } : {}),
      ...(deviceType !== '' ? { device_type: deviceType } : {}),
      ...(dashboardDevices !== '' ? { devices: dashboardDevices } : {}),
    },
  })
}

export const updateAccount = async (accountnameValue, phoneValue, settingsData) => {
  return axiosInstance.put(`/accounts`, {
    ...(accountnameValue !== '' ? { accountname: accountnameValue } : {}),
    ...(phoneValue !== '' ? { phone: phoneValue } : {}),
    ...(settingsData?.length ? { settings: settingsData } : {}),
  })
}

export const addUser = async (
  userEmailValue,
  userPhoneNumber,
  userLoginValue,
  userFirstName,
  userLastName,
  userGroupId,
  userPasswordValue,
) => {
  return axiosInstance.post(
    `/users`,

    {
      email: userEmailValue,
      login: userLoginValue,
      security_group: userGroupId,
      first_name: userFirstName,
      last_name: userLastName,
      phone: userPhoneNumber,
      password: userPasswordValue,
      settings: {},
    },
  )
}

export const updateUser = async (
  userIdValue,
  userPhoneNumber,
  userFirstName,
  userLastName,
  userGroupId,
) => {
  return axiosInstance.put(
    `/users/${userIdValue}`,

    {
      ...(userFirstName !== '' ? { fname: userFirstName } : {}),
      ...(userLastName !== '' ? { lname: userLastName } : {}),
      ...(userPhoneNumber !== '' ? { mphone: userPhoneNumber } : {}),
      ...(userGroupId !== '' ? { group_id: userGroupId } : {}),
    },
  )
}

export const updateDevice = async (deviceIdRedux, req) => {
  return axiosInstance.put(
    `/devices/${deviceIdRedux}`,

    req,
  )
}

export const addDevice = async (req) => {
  return axiosInstance.post(
    `/devices`,

    req,
  )
}

export const getGroupIdList = async () => {
  return axiosInstance.get(`/security-groups`)
}

export const getDeviceTypes = async () => {
  return axiosInstance.get(`/devices/types`)
}

export const addSite = async (
  siteName,
  descriptionValue,
  siteEmailList,
  usersList,
  permissionCheck,
) => {
  return axiosInstance.post('/sites', {
    site: siteName,
    description: descriptionValue,
    enforceSitePermission: permissionCheck,
    ...(siteEmailList?.length ? { emails: siteEmailList } : {}),
    ...(usersList?.length ? { userAccessList: usersList } : {}),
  })
}
export const updateSite = async (
  siteId,
  siteName,
  descriptionValue,
  siteEmailList,
  usersList,
  permissionCheck,
) => {
  return axiosInstance.put(`/sites/${siteId}`, {
    site: siteName,
    description: descriptionValue,
    emails: siteEmailList,
    enforceSitePermission: permissionCheck,
    userAccessList: usersList,
  })
}

export const addLane = async (laneNameLocal, laneSiteIdLocal, laneIpLocal, lanePortLocal) => {
  return axiosInstance.post('/lanes', {
    siteId: laneSiteIdLocal,
    laneName: laneNameLocal,
    IPAddress: laneIpLocal,
    ...(lanePortLocal !== '' ? { port: lanePortLocal } : {}),
  })
}
export const updateLane = async (
  laneNameLocal,
  laneSiteLocal,
  laneIpLocal,
  laneIdValue,
  lanePortLocal,
) => {
  return axiosInstance.put(`/lanes/${laneIdValue}`, {
    siteId: laneSiteLocal,
    laneName: laneNameLocal,
    IPAddress: laneIpLocal,
    ...(lanePortLocal !== '' ? { port: lanePortLocal } : {}),
  })
}

//Create dashboard
export const createDashboard = async (params) => {
  return axiosInstance.post('/dashboards', params)
}

//Update dashboard
export const updateDashboard = async (dashboardId, params) => {
  return axiosInstance.put(`/dashboards/${dashboardId}`, params)
}

//Delete dashboard
export const delDashboard = async (dashboardId) => {
  return axiosInstance.delete(`/dashboards/${dashboardId}`)
}

export const getWidgetType = async () => {
  return axiosInstance.get(`/widgets/types`)
}

export const deleteWidget = async (widgetId) => {
  return axiosInstance.delete(`/widgets/${widgetId}`)
}

export const addWidgetAPI = async (
  dashboardId,
  selectedWidgetId,
  xPos,
  yPos,
  width,
  height,
  settings,
) => {
  return axiosInstance.post(
    `/widgets`,

    {
      dashboardId: dashboardId,
      widgetTypeId: selectedWidgetId,
      xAxisPosition: xPos,
      yAxisPosition: yPos,
      width: width,
      height: height,
      ...(settings?.length !== 0 ? { settings: settings } : {}),
    },
  )
}

export const updateWidgetAPI = async (
  dashboardId,
  xPos,
  yPos,
  width,
  height,
  settings,
  widgetId,
  widgetTypeIdValue,
) => {
  return axiosInstance.put(
    `/widgets/${widgetId}`,

    {
      dashboardId: dashboardId,
      widgetTypeId: widgetTypeIdValue,
      xAxisPosition: xPos,
      yAxisPosition: yPos,
      width: width,
      height: height,
      ...(settings ? { settings: settings } : {}),
    },
  )
}

export const updateWidgetPositionAPI = async (widgetPositionData, widgetId, dashboardId) => {
  return axiosInstance.patch(
    `/widgets`,

    {
      widgetPositions: widgetPositionData,
    },
  )
}

//Account Alerts

export const accountAlertsList = async (alertPage, pageLimit, timeZone) => {
  return axiosInstance.get(`/custom-alerts/alerts`, {
    params: {
      page: alertPage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
    },
  })
}

export const getAccountAlert = async (id, timeZone) => {
  return axiosInstance.get(`/custom-alerts/alerts/${id}`, {
    params: {
      ...(timeZone !== '' ? { tz: timeZone } : {}),
    },
  })
}

export const getAlertTemplates = async () => {
  return axiosInstance.get(`/custom-alerts/templates`)
}

export const getAlertTemplateById = async (id) => {
  return axiosInstance.get(`/custom-alerts/templates/${id}`)
}

export const deleteAlert = async (id) => {
  return axiosInstance.delete(`/custom-alerts/alerts/${id}`)
}

export const updateAlert = async (id, req) => {
  return axiosInstance.put(`/custom-alerts/alerts/${id}`, req)
}

export const createAlert = async (req) => {
  return axiosInstance.post(`/custom-alerts/alerts`, req)
}

//Reports

export const getScheduledReports = async (devicePage, pageLimit) => {
  return axiosInstance.get(`/reports/scheduled`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
    },
  })
}

export const getScheduledReportsById = async (id) => {
  return axiosInstance.get(`/reports/scheduled/${id}`)
}

export const updateScheduledReports = async (id, req) => {
  return axiosInstance.put(`/reports/scheduled/${id}`, req)
}

export const updateScheduledReportsToggle = async (toggleFlag, id) => {
  return axiosInstance.patch(
    `/reports/scheduled/${id}`,

    {
      enabled: toggleFlag,
    },
  )
}

export const getReportTemplates = async () => {
  return axiosInstance.get(`/reports/templates`)
}

export const getReportTemplateById = async (id) => {
  return axiosInstance.get(`/reports/templates/${id}`)
}

export const getGeneratedReports = async (devicePage, pageLimit, timezone) => {
  return axiosInstance.get(`/reports/generated-reports`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      timezone,
    },
  })
}

export const generateReportTemplate = async (req) => {
  return axiosInstance.post(`/reports/generate`, req, { responseType: 'blob' })
}

export const getGeneratedReportLink = async (link) => {
  return axiosInstance.get(`/reports/generated-reports/${link}`)
}

export const scheduleReportTemplate = async (req) => {
  return axiosInstance.post(`/reports/scheduled`, req)
}

// DEVICE EXPORT
export const generateDevicesReport = async (
  siteOptionValue,
  laneOptionValue,
  deviceOptionValue,
  statusOptionValue,
  timeZone,
) => {
  return axiosInstance.get(`/devices/export/pdf`, {
    params: {
      ...(siteOptionValue !== '' ? { site: siteOptionValue } : {}),
      ...(laneOptionValue !== '' ? { lane: laneOptionValue } : {}),
      ...(deviceOptionValue !== '' ? { device_type: deviceOptionValue } : {}),
      ...(statusOptionValue !== '' ? { status: statusOptionValue } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
    },
    responseType: 'blob',
  })
}

//DEVICE RELAYS

export const getRelaysList = async (relayPage, pageLimit) => {
  return axiosInstance.get(`/device-relays`, {
    params: {
      page: relayPage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
    },
  })
}

export const fetchRelay = async (id) => {
  return axiosInstance.get(`/device-relays/${id}`)
}

export const createRelay = async (req) => {
  return axiosInstance.post(`/device-relays`, req)
}

export const updateRelay = async (id, isGenerate, req) => {
  return axiosInstance.put(`/device-relays/${id}?generate_key=${isGenerate}`, req)
}

export const deleteRelay = async (id) => {
  return axiosInstance.delete(`/device-relays/${id}`)
}

//Hazmat

export const getDynamicModuleReadings = async (
  devicePage,
  deviceFilterName,
  deviceIdentifierFilterName,
  locationFilterName,
  startDateRedux,
  endDateRedux,
  timeZone,
  paginate,
  pageLimit,
  moduleId,
  moduleType,
) => {
  return axiosInstance.get(`/dynamic-modules/${moduleId}/readings`, {
    params: {
      page: devicePage,
      moduleType: moduleType,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(deviceFilterName !== '' ? { devices: deviceFilterName } : {}),
      ...(deviceIdentifierFilterName !== ''
        ? { deviceIdentifier: deviceIdentifierFilterName }
        : {}),
      ...(locationFilterName !== '' ? { location: locationFilterName } : {}),
      ...(startDateRedux !== '' ? { range_start: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { range_end: endDateRedux } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}
//Get multiple device specifc locations
export const getMultipleDeviceLocations = async (ids) => {
  return axiosInstance.get(`/devices/unique-locations?devices=${ids}`)
}
//Device location
export const getDeviceLocation = async (id) => {
  return axiosInstance.get(`/devices/${id}/locations`)
}

export const getDeviceLocationList = async (id) => {
  return axiosInstance.get(`/devices/location-list`)
}

export const updateDeviceLocation = async (locationId, req) => {
  return axiosInstance.patch(`/devices/locations/${locationId}`, req)
}

export const addDeviceLocation = async (id, req) => {
  return axiosInstance.post(`/devices/${id}/locations`, req)
}

export const deleteDeviceLocation = async (locationId) => {
  return axiosInstance.delete(`/devices/locations/${locationId}`)
}

//Account audit log
export const getAccountAuditlog = async (
  devicePage,
  entityName,
  actionName,
  startDateRedux,
  endDateRedux,
  usersName,
  timeZone,
  paginate,
  pageLimit,
) => {
  return axiosInstance.get(`/audit-log`, {
    params: {
      page: devicePage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(entityName !== '' ? { entity: entityName } : {}),
      ...(actionName !== '' ? { action: actionName } : {}),
      ...(startDateRedux !== '' ? { rangestart: startDateRedux } : {}),
      ...(endDateRedux !== '' ? { rangeend: endDateRedux } : {}),
      ...(usersName !== '' ? { userId: usersName } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    },
  })
}

// Updates file upload
export const uploadFile = async (formData) => {
  return axiosInstance.post('/accounts/upload-file', formData)
}

//Uploaded files list
export const getUploadedFiles = async (
  uploadedFilesListPage,
  timeZone,
  paginate,
  pageLimit,
)=>{
  return axiosInstance.get('/accounts/uploaded-files', {
    params: {
      page: uploadedFilesListPage,
      ...(pageLimit !== '' ? { limit: pageLimit } : {}),
      ...(timeZone !== '' ? { tz: timeZone } : {}),
      ...(paginate !== true ? { paginate: false } : { paginate: true }),
    }}
  )
}
//Toggle alert
export const updateToggleAlert=async(req, id)=>{
  return axiosInstance.patch(`/custom-alerts/alerts/${id}/status`, req)
}
// Settings application
export const fetchAppSettings=async()=>{
  return axiosInstance.get(`settings/application`);
}